<template>
	<div>
		<!-- banner -->
		<div class="banner" :style="cover">
			<h1 class="banner-title">小淳Ai</h1>
		</div>
		<v-card class="blog-container">
			<div class="ltAitabie" style=" width: 100%; height: 460px;"
			v-loading="loading"
			element-loading-text="Ai输入中..."
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(242, 242, 242, 0.8)"
			>
				<div class="infoTop" 
				ref="chatContent"
				>
					<div :class="item.ltBianhaoai === 'Ai1.0' ? 'chatInfoLeft' : 'chatInfoRight'" v-for="item  in ltLsit">
						<img src="https://huaiqing-1301581767.cos.ap-guangzhou.myqcloud.com/huaiqing/2022/10/17/baaed91a-cd1e-43fe-a259-9b8d64cb638e.png" v-show="item.ltBianhaoai === 'Ai1.0'" alt="头像" style="width:35px; height: 35px;" />
						<img :src="tximg" v-show="item.ltBianhaoai != 'Ai1.0'" alt="头像" style="width:35px; height: 35px; height: 35px;" />
						
						<div :class="item.ltBianhaoai === 'Ai1.0' ? 'chatLeft' : 'chatRight'">
							<audio v-if="item.musicUrl!==undefined &&item.ltBianhaoai === 'Ai1.0'" v-bind:src="item.musicUrl" controls
							 autoplay></audio>
							 
							 <img :src="item.ltXinxi" v-show="item.model == '图片'" style="width:200px; height: 200px; "/>
							<div class="text" v-show="item.model != '图片'">
								{{item.ltXinxi}}
							</div>		
							<i class="el-icon-document-copy" v-show="item.ltBianhaoai === 'Ai1.0'" @click="copywxtap" :data-clipboard-text="item.ltXinxi" style=""></i>
							
						</div>
					</div>
					<!-- </div> -->
				</div>
			</div>
			<div>
			<span v-show="model == '1.0'">
				次数{{frequency}}
			</span>
			<span v-show="model == '2.0'">
				次数{{gpt4}}
			</span>
			<span v-show="model == '图片'">
				次数{{gpt4tp}}
			</span>
			
			<el-select v-model="model" placeholder="1.0" style="width: 90px">
			  <el-option label="1.0" value="1.0"></el-option>
			  <el-option label="2.0" value="2.0"></el-option>
			  <el-option label="图片" value="图片"></el-option>
			</el-select>
	<!-- 	  <div>
		    <input type="file" @change="sendFile" />
		  </div> -->
			  
			</div>
			<div style="width: 100%;">
				<span>
					<el-input v-model="ltXinxi" placeholder="请输入内容" style="width: 80%;"></el-input>
				</span>
				<span>
					<div class="fasong" @click="isMusic==0 ? sender() : musicSearchVal()" :disabled="loading"><span>发送</span></div>
				</span>
			</div>
		</v-card>

	</div>
</template>

<script>
	import {
		getPhotos,
		getWords,
		getMusicInfo,
		getMusicUrl,
		getHotMusic,
		getSearchSuggest,
		getHotTalk,
		getVipUser
	} from '../../api';
import Clipboard from 'clipboard';



	export default {
		data: function() {
			return {
				chojiang: {
					ltXinxi: "",
					ltBianhao: this.$store.state.email,
					ltMingcheng: this.$store.state.nickname,
					aiType:"Ai",
					model:"1.0",
				},
				information:{
					ltXinxi:"",
					model:"",
					sendFile:null
				},
				isMusic: 0,
				ltLsit: [],
				tximg:this.$store.state.avatar,
				istxing:0,
				ltXinxi:"",
				frequency:0,
				isOpenAi:0,
				loading: false,
				isWebsocket:0,
				model:"1.0",
				sendFiles:null,
				gpt4tp:0,
				gpt4:0
			};
		},
		methods: {
			initWebSocket() {
				// 连接错误
				this.websocket.onerror = this.setErrorMessage
				// 连接成功
				this.websocket.onopen = this.setOnopenMessage
				// 收到消息的回调
				this.websocket.onmessage = this.setOnmessageMessage
				// 连接关闭的回调
				this.websocket.onclose = this.setOncloseMessage
				// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
			},
			setErrorMessage() {
				//window.console.log('WebSocket连接发生错误，状态码：' + this.websocket.readyState)
			},
			setOnopenMessage() {
				window.console.log('WebSocket连接成功，状态码：' + this.websocket.readyState)
				this.send(this.ltLsit[this.ltLsit.length-2].ltXinxi);
				this.isWebsocket=0;
			},
			setOnmessageMessage(event) {
				this.loading=false;
				if(event=="异常"){
					this.ltLsit[this.ltLsit.length-1].ltXinxi=this.ltLsit[this.ltLsit.length-1].ltXinxi+"服务器计算火爆~正在为您更换服务器，请重新输入";
					return;
				}
				if(event=="次数不足"){
					this.ltLsit[this.ltLsit.length-1].ltXinxi=this.ltLsit[this.ltLsit.length-1].ltXinxi+event;
				return;
				}
				  if (event.data == "[DONE]") {return;}
				  if(this.model == "图片"){
				  	this.ltLsit[this.ltLsit.length-1].ltXinxi=event.data;
					this.ltLsit[this.ltLsit.length-1].model="图片";
				  					return;
				  }
				  let json_data = JSON.parse(event.data)
				  if (json_data.content == null || json_data.content == 'null') {return;}
				this.ltLsit[this.ltLsit.length-1].ltXinxi=this.ltLsit[this.ltLsit.length-1].ltXinxi+json_data.content;
				
			},
			setOncloseMessage() {
				window.console.log('WebSocket连接关闭，状态码：' + this.websocket.readyState)
			},
			onbeforeunload() {
				this.closeWebSocket()
			},
			closeWebSocket() {
				this.websocket.close()
			},
			send(message) {
				if (this.sendFiles!==null) {
					this.information.sendFile=this.sendFiles;
				}
				this.information.ltXinxi=message;
				this.information.model=this.model;
				var json = JSON.stringify(this.information);
				this.websocket.send(json)
				

				
				this.sendFiles=null;
				this.information.sendFile=null;
				
				if (this.model=="1.0") {
					this.frequency=this.frequency-1;
					return;
				}else if (this.model=="2.0") {
				this.gpt4=this.gpt4-1;
					return;
				}else {
	         	this.gpt4tp=this.gpt4tp-1;	
				}
				
			},


 copywxtap() {
      var clipboard = new Clipboard(".el-icon-document-copy");
      clipboard.on("success", (e) => {
		  this.$toast({
		  	type: "warning",
		  	message: "复制成功"
		  });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
		this.$toast({
			type: "warning",
			message: "该浏览器不支持自动复制"
		});
        // 释放内存
        clipboard.destroy();
      });
    },

   sendFile(event) {
       const file = event.target.files[0];
       const reader = new FileReader();
       reader.onload = () => {
           let arrayBuffer  = reader.result;
		   let uint8Array = new Uint8Array(arrayBuffer);
		    let base64String = btoa(String.fromCharCode.apply(null, uint8Array));
           this.sendFiles = base64String;
       };
       reader.readAsArrayBuffer(file);
   },
   
   

			
			sender() {
				if(this.loading==true){
					this.$toast({
						type: "warning",
						message: "请稍等~"
					});
					return;
				}
				if (this.frequency == 0&&this.chojiang.aiType=="OpenAi"&&this.model=="1.0") {
					var newChojiang = {
						ltXinxi: "今天次数不足请更换ai，或者联系作者",
						ltBianhaoai: "Ai1.0"
					}
					this.ltLsit.push(newChojiang);
					return;
				}
				if (this.gpt4tp == 0&&this.chojiang.aiType=="OpenAi"&&this.model=="图片") {
					var newChojiang = {
						ltXinxi: "次数不足请更换ai，或者联系作者",
						ltBianhaoai: "Ai1.0"
					}
					this.ltLsit.push(newChojiang);
					return;
				}
				if (this.gpt4 == 0&&this.chojiang.aiType=="OpenAi"&&this.model=="2.0") {
					var newChojiang = {
						ltXinxi: "次数不足请更换ai，或者联系作者",
						ltBianhaoai: "Ai1.0"
					}
					this.ltLsit.push(newChojiang);
					return;
				}
				if (this.$store.state.nickname === "" || this.$store.state.nickname === null) {
					this.openLogin();
					this.$toast({
						type: "success",
						message: "请先登录"
					});
					this.istxing = 1;
					return;
				} else if (this.istxing == 1) {
					this.tximg = this.$store.state.avatar;
					this.chojiang.ltBianhao=this.$store.state.email;
					this.chojiang.ltMingcheng=this.$store.state.nickname;
					this.istxing = 0;
				} else if (this.ltXinxi === "") {
					return;
				} else if (this.websocket == null&&this.chojiang.aiType=="OpenAi") {
					this.websocket = new WebSocket('ws:///8.137.54.39:8800/shiyi/websocket/' + this.$store.state.email);
					this.initWebSocket();
					this.isWebsocket=1;
				}
				this.chojiang.ltXinxi=this.ltXinxi;
				this.ltXinxi="";				
				var newChojiang = Object.assign({}, this.chojiang);
				this.ltLsit.push(newChojiang)
				if (this.chojiang.ltXinxi == "音乐") {
					var musicChojiang = {
						ltBianhaoai: "Ai1.0",
						ltXinxi: "请输入音乐名字",
						musicUrl: undefined
					}
					this.ltLsit.push(musicChojiang)
					this.isMusic = 1;
					return;
				}
				 if (this.chojiang.aiType=="OpenAi") {
				 	var newChojiang2 = {
				 		ltXinxi: "",
				 		ltBianhaoai: "Ai1.0"
				 	}
				 	this.ltLsit.push(newChojiang2);
					if(this.isWebsocket==0){
					this.send(this.ltLsit[this.ltLsit.length-2].ltXinxi);	
					}
					this.loading=true;
					getPhotos(this.chojiang).then(res => {
					})
				 	return;
				 }else {
					this.loading=true;
					getPhotos(this.chojiang).then(res => {
						this.loading=false;
						this.ltLsit.push(res.data)
					})
				}
			},
			//ai音乐
			musicSearchVal() {
				this.chojiang.ltXinxi=this.ltXinxi;
				this.ltXinxi="";
				if (this.chojiang.ltXinxi == "退出") {
					var musicChojiang = {
						ltBianhaoai: "Ai1.0",
						ltXinxi: "音乐退出成功"
					}
					this.ltLsit.push(musicChojiang)
					this.isMusic = 0;
					this.chojiang.ltXinxi = ""
				} else {
					var musicSearchList = [];
					var th = this;
					if (this.ltLsit[this.ltLsit.length - 1].musicUrl !== undefined) {
						var musicChojiang = {
							ltBianhaoai: "Ai1.0",
							musicUrl: undefined,
							ltXinxi: "呜呜呜我的播放器被占领啦,如想退出请输入“退出”"
						}
						this.ltLsit[this.ltLsit.length - 1] = musicChojiang;

					}

					if (this.chojiang.ltXinxi === "") {
						return
					}
					var newChojiang = Object.assign({}, this.chojiang);
					this.ltLsit.push(newChojiang)
					getSearchSuggest(this.chojiang.ltXinxi).then(res => {
						musicSearchList = res.data.result.songs;
						getMusicUrl(musicSearchList[0].id).then(res => {
							var musicChojiang = {
								ltBianhaoai: "Ai1.0",
								musicUrl: res.data.data[0].url.replace("http://", "https://")
							}
							this.ltLsit.push(musicChojiang)
							this.chojiang.ltXinxi = ""
						});
					});
				}
			},
			scrollToBottom() {
				this.$nextTick(() => {
					this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
				})
			},
			openLogin() {
				this.$store.state.loginFlag = true;
			}
		},

		mounted() {
			getVipUser(this.$store.state.email).then(res => {
				this.gpt4tp=res.data.gpt4tp;
				this.gpt4=res.data.gpt4;
				this.frequency=res.data.frequency;
			});
			this.isOpenAi=1;
			var newChojiang = {
				ltXinxi: "您好！文件阅读功能正在研发中，系统运算可能有点慢。",
				ltBianhaoai: "Ai1.0"
			}
			this.ltLsit.push(newChojiang);
			this.chojiang.aiType="OpenAi";
	
			// dibootApi.get("dsXinchou/listAi", this.cz).then(res => {
			//   this.ltLsit = res.data;
			//   })
			this.scrollToBottom()
		},
		updated() {
			this.scrollToBottom()
		},
		computed: {
			cover() {
				var cover = "";
				this.$store.state.blogInfo.pageList.forEach(item => {
					if (item.pageLabel === "album") {
						cover = item.pageCover;
					}
				});
				return "background: url(" + cover + ") center center / cover no-repeat";
			}
		}
	};
</script>

<style scoped>
	.album-item {
		overflow: hidden;
		position: relative;
		cursor: pointer;
		background: #000;
		border-radius: 0.5rem !important;
	}

	.album-cover {
		position: relative;
		max-width: none;
		width: calc(100% + 1.25rem);
		height: 250px;
		opacity: 0.8;
		transition: opacity 0.35s, transform 0.35s;
		transform: translate3d(-10px, 0, 0);
		object-fit: cover;
	}

	.album-wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1.8rem 2rem;
		color: #fff !important;
	}

	.album-item:hover .album-cover {
		transform: translate3d(0, 0, 0);
		opacity: 0.4;
	}

	.album-item:hover .album-name:after {
		transform: translate3d(0, 0, 0);
	}

	.album-item:hover .album-desc {
		opacity: 1;
		filter: none;
		transform: translate3d(0, 0, 0);
	}

	.album-name {
		font-weight: bold;
		font-size: 1.25rem;
		overflow: hidden;
		padding: 0.7rem 0;
		position: relative;
	}

	.album-name:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: #fff;
		content: "";
		transition: transform 0.35s;
		transform: translate3d(-101%, 0, 0);
	}

	.album-desc {
		margin: 0;
		padding: 0.4rem 0 0;
		line-height: 1.5;
		opacity: 0;
		transition: opacity 0.35s, transform 0.35s;
		transform: translate3d(100%, 0, 0);
	}

	.ltAitabie {
		border: none;
	}

	/* 信息列表 */
	.infoTop {
		height: 100%;
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		background-color: #f2f2f2;
		overflow: auto;
	}

	/* 对方发的信息样式 */
	.chatInfoLeft {
		min-height: 70px;
		margin-left: 10px;
		margin-top: 10px;
		display: flex;
	}

	.chatLeft {
		margin-left: 15px;
		flex: 1;
	}

	.fasong {
		height: 37px;
		width: 15%;
		background-color: #e8e8e8;
		text-align: center;
		line-height: 37px;
		border-radius: 4px;
		color: #58df4d;
		align-self: flex-end;
		cursor: pointer;
		float: right;
	}

	.chatLeft .text {
		color: #434343;
		margin-top: 8px;
		background-color: #e3e3e3;
		display: inline-block;
		padding: 6px 10px;
		border-radius: 10px;
		 max-width: 90%; 
		/* 忽略多余的空白，只保留一个空白 */
		white-space: normal;
		/* 换行显示全部字符 */
		word-break: break-all;
		white-space: pre-wrap;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
	}

	/* 自己发的信息样式 */
	.chatInfoRight {
		margin-left: 10px;
		margin-top: 10px;
		display: flex;
		flex-direction: row-reverse;
	}

	.chatRight {
		margin-right: 15px;
		flex: 1;
		/* 用align-items把元素靠右对齐 */
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.chatRight .text {
		color: #434343;
		margin-top: 8px;
		background-color: #95ec69;
		display: inline-block;
		padding: 6px 10px;
		border-radius: 10px;
		max-width: 90%;
		/* 忽略多余的空白，只保留一个空白 */
		white-space: normal;
		/* 换行显示全部字符 */
		word-break: break-all;
		white-space: pre-wrap;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
	}
</style>
